import { IDebtorKpi } from 'apps/middle/src/app/debtors-module/models';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { createEntitySliceSelectors, createListSliceSelectors, createLetteringSelectors } from '../functions';

import { IDebtorPageState } from './state';
import { FeatureName } from './feature';
import { getAccountingDocumentById } from './reducer';



export const selectState: MemoizedSelector<object, IDebtorPageState> = createFeatureSelector<IDebtorPageState>(FeatureName);

const getDebtorKpi = (state: IDebtorPageState): IDebtorKpi => state.identity ? state.identity.entity.kpi : null;
const getIsLoading = (state: IDebtorPageState): boolean => state.isLoading;
const getError = (state: IDebtorPageState): any => state.error;
const getIsLoadingOrUpdatingContact = (state: IDebtorPageState): any => state.contacts.isLoading || state.contactForm.isLoading;

export const selectDebtorKpi = createSelector(selectState, getDebtorKpi);
export const selectIsLoading = createSelector(selectState, getIsLoading);
export const selectError = createSelector(selectState, getError);

export const {
	selectEntity: selectDebtorIdentity,
	selectError: selectDebtorIdentityError,
	selectIsLoading: selectDebtorIdentityIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.identity));

export const {
	selectEntity: selectRelations,
	selectError: selectRelationsError,
	selectIsLoading: selectRelationsIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.relations));

export const {
	selectEntity: selectContactForm,
	selectError: selectContactFormError,
	selectIsLoading: selectContactFormIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.contactForm));

export const {
	selectEntity: selectContacts,
	selectError: selectContactsError,
} = createEntitySliceSelectors(createSelector(selectState, state => state.contacts));

export const selectContactsIsLoading = createSelector(selectState, getIsLoadingOrUpdatingContact);

export const {
	selectSlice: selectAccountingSlice,
	selectList: selectAccounting,
	selectFilters: selectAccountingFilters,
	selectError: selectAccountingError,
	selectIsLoading: selectAccountingIsLoading,
	selectPaging: selectAccountingPaging,
} = createListSliceSelectors(createSelector(selectState, state => state.accountingDocuments));

const getShowCompleted = (state: IDebtorPageState): boolean => state.showCompleted;
export const selectShowCompleted = createSelector(selectState, getShowCompleted);

export const {
	getAccountingDocument,
	getLetteredList,
	getLetteredListIsLoading,
	getLetteredListIsOpened,
	getLetteredListError,
	getLetteredListState,

	selectLetteredList,
	selectLetteredListIsOpened,
	selectLetteredListIsLoading,
	selectLetteredListError,
	selectLetteredListState,
} = createLetteringSelectors(selectState, (state, id) => getAccountingDocumentById(state[FeatureName], id));

export const {
	selectSlice: selectMailsSlice,
	selectList: selectMails,
	selectFilters: selectMailsFilters,
	selectError: selectMailsError,
	selectIsLoading: selectMailsIsLoading,
	selectPaging: selectMailsPaging
} = createListSliceSelectors(createSelector(selectState, state => state.mails));

export const {
	selectSlice: selectLettersSlice,
	selectList: selectLetters,
	selectFilters: selectLettersFilters,
	selectError: selectLettersError,
	selectIsLoading: selectLettersIsLoading,
	selectPaging: selectLettersPaging
} = createListSliceSelectors(createSelector(selectState, state => state.letters));

export const selectDebtorId = createSelector(selectState, state => state.debtorId)
export const selectSuperDebtorId = createSelector(selectState, state => state.superDebtorId)
