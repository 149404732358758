
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FeatureName } from './feature';
import { debtorPageReducer } from './reducer';
import { DebtorPageStoreEffects } from './effects';


@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureName, debtorPageReducer),
		EffectsModule.forFeature([DebtorPageStoreEffects])
	]
})

export class DebtorPageStoreModule { }
