

import { IDebtorIdentity, IDebtorContact, IDebtorRelationItem, IDebtorContactForm } from 'apps/middle/src/app/debtors-module/models';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IAccountingItemList, IAccountingListFilter } from 'apps/middle/src/app/accounting-module/models';
import { AccountingListSortProp } from 'apps/middle/src/app/accounting-module/enums';
import { IEmailList, ILetterList } from 'apps/middle/src/app/shared-module/models';
import { IFilter, IListState, createDefaultListState } from '@aston/foundation';
import { AppConstants } from 'apps/middle/src/app/app.constants';

import { createListSlice, createEntitySlice, IStoreEntityStateSlice, IStoreListStateSlice } from '../functions';

import { FeatureName } from './feature';


export interface IDebtorPageState {
	isLoading: boolean;
	error: string;
	debtorId: number;
	superDebtorId: number;
	identity: IStoreEntityStateSlice<IDebtorIdentity>;

	contacts: IStoreEntityStateSlice<IDebtorContact[]>;

	contactForm: IStoreEntityStateSlice<IDebtorContactForm>;
	contactFormModal: NgbModalRef;

	relations: IStoreEntityStateSlice<IDebtorRelationItem[]>;

	accountingDocuments: IStoreListStateSlice<IAccountingItemList, AccountingListSortProp, IAccountingListFilter>;
	showCompleted: boolean;
	letteringDefaultPaging: IListState<AccountingListSortProp>;

	mails: IStoreListStateSlice<IEmailList, any, IFilter>;
	letters: IStoreListStateSlice<ILetterList, any, IFilter>;
}

export const initialState: IDebtorPageState = {
	// Base
	isLoading: false,
	error: null,
	debtorId: null,
	superDebtorId: null,
	identity: createEntitySlice(),

	// Contacts
	contacts: createEntitySlice({ entity: [] }),

	contactFormModal: null,
	contactForm: createEntitySlice(),

	// Debtors relations
	relations: createEntitySlice({ entity: [] }),

	// Accounting
	accountingDocuments: createListSlice({ prop: AccountingListSortProp.DueDate, isDesc: false }, AppConstants.LIST_PAGE_SIZE_DEFAULT, [], `${FeatureName}.accountingDocuments`),
	showCompleted: false,
	letteringDefaultPaging: createDefaultListState(AccountingListSortProp.AccountingDate, false, AppConstants.LIST_PAGE_SIZE_IGNORED),

	mails: createListSlice({ prop: null, isDesc: true }, AppConstants.LIST_PAGE_SIZE_IGNORED),
	letters: createListSlice({ prop: null, isDesc: true }, AppConstants.LIST_PAGE_SIZE_IGNORED),
};
