import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, switchMap, map, withLatestFrom, debounceTime, takeUntil, filter, take } from 'rxjs/operators';
import { DebtorsService, SuperDebtorsListService } from 'apps/middle/src/app/debtors-module/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DebtorDetailContactFormComponent } from 'apps/middle/src/app/debtors-module/components/debtor-detail-contact-form/debtor-detail-contact-form.component';
import { StoreLoader, createDefaultListState, removeFilterBySpec } from '@aston/foundation';
import { DebtorsRelationsSortProp } from 'apps/middle/src/app/debtors-module/enums';
import { AppConstants } from 'apps/middle/src/app/app.constants';
import { AccountingDocumentsService, AccountingListFilterSpecService } from 'apps/middle/src/app/accounting-module/services';
import { PdfViewerComponent } from 'apps/middle/src/app/shared-module/components/pdf-viewer/pdf-viewer.component';
import { DocumentFileService, WebSocketService } from 'apps/middle/src/app/shared-module/services';
import { WsEventType } from 'apps/middle/src/app/shared-module/enums';

import { IDebtorContactForm } from '../../debtors-module/models';
import { addAccountingSubTypeSorts } from '../functions';
import { CorrelationParams, aggregate } from '../models/correlated-actions.model';
import { catchWithAppError as catchError, AppStoreActions, AppStoreSelectors } from '../app-store';
import { ReferentialStoreSelectors } from '../referential-store';
import { MailWriterStore } from '../../shared-module/stores';

import { getAccountingDocumentById } from './reducer';
import * as DebtorPageStoreSelectors from './selectors';
import * as featureSelectors from './selectors';
import * as featureActions from './actions';

@Injectable({
	providedIn: 'root'
})
export class DebtorPageStoreEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private accountingService: AccountingDocumentsService,
		private debtorDetailService: DebtorsService,
		protected modalService: NgbModal,
		protected mailWriter: MailWriterStore,
		private ws: WebSocketService,
		private superDebtorsListService: SuperDebtorsListService,
		private accountingListFilterSpecService: AccountingListFilterSpecService,
		private documentFileService: DocumentFileService) {
	}

	onDebug$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.Debug),
		tap(action => console.log(`Debug action %o triggered by`, action.message, action.origin))
	), {dispatch: false});

	initDebtorEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitDebtor),
		switchMap(action => {
			const correlationParams: CorrelationParams = action.correlationParams;
			correlationParams.parentActionType = action.type;

			return [
				featureActions.LoadDebtorIdentityRequest(correlationParams),
				featureActions.LoadDebtorRelationsRequest(correlationParams),
				featureActions.LoadDebtorContactsRequest(correlationParams)
			];
		}),
		catchError(error => of(featureActions.InitDebtorFailure({error})))
	));

	initDebtorSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitDebtor),
		// Create an aggregated action listener
		// it will observe the race of all specified observable
		// and complete when all these actions are dispatched.
		// Note that individual actions workflow still apply.
		aggregate(
			this.actions$.pipe(ofType(featureActions.LoadDebtorIdentitySuccess)),
			this.actions$.pipe(ofType(featureActions.LoadDebtorContactsSuccess)),
			this.actions$.pipe(ofType(featureActions.LoadDebtorRelationsSuccess)),
			this.actions$.pipe(ofType(featureActions.InitDebtorFailure)),
		),
		map(_ => featureActions.InitDebtorSuccess()),
		catchError(error => of(featureActions.InitDebtorFailure({error})))
	));

	loadDebtorIdentityRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDebtorIdentityRequest),
		withLatestFrom(this.store.select(DebtorPageStoreSelectors.selectState)),
		switchMap(([action, state]) =>
			this.debtorDetailService.getDebtorIdentity(state.superDebtorId, state.debtorId).pipe(
				map(entity => featureActions.LoadDebtorIdentitySuccess({entity, correlationParams: action.correlationParams})),
				catchError(error => of(featureActions.InitDebtorFailure({error})))
			)
		)
	));

	loadDebtorRelationsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDebtorRelationsRequest),
		withLatestFrom(this.store.select(featureSelectors.selectSuperDebtorId)),
		switchMap(([action, superDebtorId]) => {
			const paging = createDefaultListState(DebtorsRelationsSortProp.SELLER_NAME, false, AppConstants.LIST_PAGE_SIZE_IGNORED);

			return this.superDebtorsListService.getDebtorsRelations(superDebtorId, paging).pipe(
				map(entity => featureActions.LoadDebtorRelationsSuccess({entity, correlationParams: action.correlationParams})),
				catchError(error => of(featureActions.InitDebtorFailure({error})))
			);
		})
	));

	loadDebtorContactsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDebtorContactsRequest),
		withLatestFrom(this.store.select(DebtorPageStoreSelectors.selectState)),
		switchMap(([action, state]) => this.debtorDetailService.getDebtorContacts(state.debtorId).pipe(
			map(entity => featureActions.LoadDebtorContactsSuccess({entity, correlationParams: action.correlationParams})),
			catchError(error => of(featureActions.LoadDebtorContactsFailure({error})))
			)
		)
	));

	reloadContactsAfterImport$ = createEffect(() => this.ws.ofType(WsEventType.ImportCompletion).pipe(
		withLatestFrom(this.store.select(featureSelectors.selectDebtorIdentity)),
		filter(([_, debtor]) => !!debtor?.id),
		map(_ => featureActions.LoadDebtorContactsRequest())
	));

	onOpenDebtorContactForm$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(featureActions.OpenDebtorContactForm),
			withLatestFrom(this.store.select(AppStoreSelectors.selectCurrentLanguage)),
			switchMap(([action, state]) => this.store.select(ReferentialStoreSelectors.selectLanguages(state.language)).pipe(
				take(1),
				map(languages => {
					const modalRef = this.modalService.open(DebtorDetailContactFormComponent, AppConstants.DEFAULT_MODAL_OPTIONS);
					const modal = (modalRef.componentInstance as DebtorDetailContactFormComponent);

					modal.languages = languages;
					modal.contact = action.contact;
					modal.submitForm.pipe(
						takeUntil(modal.destroySubscriptions$)
					).subscribe((entity: IDebtorContactForm) =>
						!entity.id ?
							this.store.dispatch(featureActions.CreateContactRequest(entity))
							: this.store.dispatch(featureActions.UpdateContactRequest(entity))
					);

					modal.formLoader = new StoreLoader(
						this.store.select(DebtorPageStoreSelectors.selectContactFormIsLoading),
						this.store.select(DebtorPageStoreSelectors.selectContactFormError)
					);

					return featureActions.SetDebtorContactFormModal({modal: modalRef});
				})
			)),
		);
	});

	/* Update contact form */
	onUpdateContactRequestEffect = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateContactRequest),
		withLatestFrom(this.store.select(DebtorPageStoreSelectors.selectState)),
		switchMap(([action, state]) =>
			this.debtorDetailService.updateContact(state.debtorId, action.entity).pipe(
				switchMap(_ => [
					featureActions.UpdateContactSuccess({entity: action.entity}),
					featureActions.LoadDebtorContactsRequest()
				]),
				tap(_ => state.contactFormModal.close()),
				catchError(error => of(featureActions.UpdateContactFailure({error})))
			)
		)));

	/* Create contact form */
	onCreateContactEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.CreateContactRequest),
		withLatestFrom(this.store.select(DebtorPageStoreSelectors.selectState)),
		switchMap(([action, state]) =>
			this.debtorDetailService.createContact(state.debtorId, action.entity).pipe(
				switchMap(_ => [
					featureActions.CreateContactSuccess({entity: action.entity}),
					featureActions.LoadDebtorContactsRequest()
				]),
				tap(_ => state.contactFormModal.close()),
				catchError(error => of(featureActions.CreateContactFailure({error})))
			)
		)
	));

	loadAccountingEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAccountingRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectAccountingFilters),
			this.store.select(featureSelectors.selectAccountingPaging),
			this.store.select(featureSelectors.selectDebtorId),
			this.store.select(featureSelectors.selectShowCompleted)),
		debounceTime(500),
		switchMap(([_, otherFilters, paging, debtorId, showCompleted]) => {

			const {
				excludeCompletedDocuments,
				excludeMiscDocuments
			} = this.accountingListFilterSpecService;

			// hard filters
			// https://astonitf.visualstudio.com/LBP/_workitems/edit/10816
			const filters = [
				excludeMiscDocuments,
				...otherFilters
			];

			removeFilterBySpec(filters, excludeCompletedDocuments.spec);
			if (!showCompleted) {
				filters.push(excludeCompletedDocuments);
			}

			// If sorting on type, add paymentType as second sort
			paging = {
				...paging,
				sorts: addAccountingSubTypeSorts(paging.sorts)
			};

			return this.debtorDetailService.getDebtorAccounting(debtorId, { ...paging, filters }).pipe(
				map(list => featureActions.LoadAccountingSuccess({list})),
				catchError(error => of(featureActions.LoadAccountingFailure({error})))
			);
		})
	));

	// Reload data when a UpdateSettingsAction is dispatched
	updateAccountingSettingsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAccountingSettings, featureActions.SetShowCompleted),
		map(_ => featureActions.LoadAccountingRequest())
	));

	// Reload data when a UpdateFilterAction is dispatched
	updateAccountingFilterEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddAccountingFilters, featureActions.RemoveAccountingFilters),
		map(_ => featureActions.LoadAccountingRequest())
	));

	UpdateLetteredDocumentSettings$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateLetteredDocumentSettings),
		map(action => featureActions.LoadLetteredDocumentRequest({id: action.id, letteringCode: action.letteringCode}))
	));

	onToggleLetteredDocument$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ToggleLetteredDocument),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		map(([action, state]) => {
			const accountingDocument = getAccountingDocumentById(state, action.id);
			return accountingDocument.isLetteredListOpened ? featureActions.LoadLetteredDocumentRequest({
				id: action.id, letteringCode: action.letteringCode
			}) : AppStoreActions.Noop();
		})
	));

	onLoadLetteredDocumentRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadLetteredDocumentRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectState),
			this.store.select(featureSelectors.selectSuperDebtorId)),
		switchMap(([action, state, superDebtorId]) => {
		const accountingDocument = getAccountingDocumentById(state, action.id);

			return this.accountingService.getAccountingDocumentsLetteredBy(
				superDebtorId,
				action.letteringCode,
				accountingDocument.letteredListPaging).pipe(
				map(documents => featureActions.LoadLetteredDocumentSuccess({id: action.id, letteringList: documents})),
				catchError(_ => of(featureActions.Debug({message: 'onLoadLetteredDocumentRequest error', origin: action})))
			);
		})
	));

	sendMail$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SendDebtorMailRequest),
		withLatestFrom(this.store.select(featureSelectors.selectSuperDebtorId)),
		switchMap(([action, superDebtorId]) => this.debtorDetailService.sendEmailToSuperDebtor(superDebtorId, action.entity).pipe(
			switchMap(_ => [
				featureActions.SendDebtorMailSuccess({entity: action.entity, correlationParams: action.correlationParams}),
				AppStoreActions.Execute(() => this.mailWriter.onMailSent()),
				featureActions.LoadDebtorMailsRequest()
			]),
			catchError(error => of(
				featureActions.SendDebtorMailFailure({error}),
				AppStoreActions.Execute(() => this.mailWriter.onMailError(error)),
			))
		))
	));

	getEmailPreview = createEffect(() => this.actions$.pipe(
		ofType(featureActions.GetEmailPreview),
		withLatestFrom(this.store.select(featureSelectors.selectSuperDebtorId)),
		switchMap(([action, superDebtorId]) => this.debtorDetailService.getMailPreview(superDebtorId, action.mailData).pipe(
			map(entity => AppStoreActions.Execute(() => this.mailWriter.onPreviewSuccess(entity))),
			catchError(error => of(AppStoreActions.Execute(() => this.mailWriter.onPreviewError(error))))
		))
	));

	loadMails = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDebtorMailsRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectMailsSlice),
			this.store.select(featureSelectors.selectDebtorId)),
		switchMap(([_, mailsSlice, debtorId]) => {
			const { filters, paging } = mailsSlice;
			return this.debtorDetailService.getSentMails(debtorId, { ...paging, filters }).pipe(
				map(list => featureActions.LoadDebtorMailsSuccess({list})),
				catchError(error => of(featureActions.LoadDebtorMailsFailure({error})))
			);
		})
	));

	openSentMail$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenSentMailRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectDebtorId),
			this.store.select(featureSelectors.selectContacts)),
		switchMap(([action, debtorId, contacts]) => this.debtorDetailService.getSentMail(debtorId, action.id, contacts).pipe(
			switchMap(entity => [
				featureActions.OpenSentMailSuccess({entity}),
				AppStoreActions.Execute(() => this.mailWriter.onMailSent()),
			]),
			catchError(error => of(
				featureActions.OpenSentMailFailure({error}),
				AppStoreActions.Execute(() => this.mailWriter.onMailError(error))
			))
		))
	));

	loadLetterss = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDebtorLettersRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectLettersSlice),
			this.store.select(featureSelectors.selectDebtorId)),
		switchMap(([_, letters, debtorId]) => {
			const { filters, paging } = letters;
			return this.debtorDetailService.getSentLetters(debtorId, { ...paging, filters }).pipe(
				map(list => featureActions.LoadDebtorLettersSuccess({list})),
				catchError(error => of(featureActions.LoadDebtorLettersFailure({error})))
			);
		})
	));

	openSentLetterRequest = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenSentLetterRequest),
		switchMap(action => this.documentFileService.getLocalFileURL({id: action.id, name: ''}).pipe(
			map(document => featureActions.OpenSentLetterSuccess({param: <string> document.url['changingThisBreaksApplicationSecurity']})),
			catchError(error => of(featureActions.OpenSentLetterFailure({error})))
		))
	));

	openSentLetterSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenSentLetterSuccess),
		tap(action => {
			const modalRef = this.modalService.open(PdfViewerComponent, AppConstants.DEFAULT_MODAL_OPTIONS);
			const modalInstance: PdfViewerComponent = modalRef.componentInstance;
			modalInstance.title = 'Actions.PreviewPostalMail';
			modalInstance.url = action.param;
		})
	), {dispatch: false});

}
