import { createReducer, on } from '@ngrx/store';
import { IAccountingListItem } from 'apps/middle/src/app/accounting-module/models';
import { reuseListSorts } from '@aston/foundation';

import { withListReducer, withEntityReducer, withLetteringReducer, getAccountingDocumentByIdInList } from '../functions';

import * as featureActions from './actions';
import { initialState, IDebtorPageState } from './state';


export const debtorPageReducer = createReducer<IDebtorPageState>(
	initialState,

	...withListReducer<IDebtorPageState>('accountingDocuments', featureActions.accountingListActions),
	...withLetteringReducer<IDebtorPageState>('accountingDocuments', getAccountingDocumentById, featureActions.letteringActions),
	...withEntityReducer<IDebtorPageState>('contacts', featureActions.debtorContactsActions),
	...withEntityReducer<IDebtorPageState>('contactForm', featureActions.createContactActions),
	...withEntityReducer<IDebtorPageState>('relations', featureActions.debtorRelationsActions),
	...withEntityReducer<IDebtorPageState>('identity', featureActions.debtorIdentityActions),
	...withListReducer<IDebtorPageState>('mails', featureActions.debtorMailsActions),
	...withListReducer<IDebtorPageState>('letters', featureActions.debtorLettersActions),

	on(featureActions.InitDebtor, (state, action) => ({
		...initialState,
		error: null,
		isLoading: true,
		debtorId: action.debtorId,
		superDebtorId: action.superDebtorId,
		// Reuse existing sorts
		// User Story 29225: [ACC][Client] Personnalisation des tris sur les listes au sein de la plateforme
		accountingDocuments: reuseListSorts(initialState.accountingDocuments, state.accountingDocuments),
	})),

	on(featureActions.InitDebtorSuccess, state => ({
		...state,
		error: null,
		isLoading: false,
	})),

	on(featureActions.InitDebtorFailure, (state, action) => ({
		...state,
		error: action.error.message,
		isLoading: false
	})),

	on(featureActions.SetDebtorContactFormModal, (state, action) => ({
		...state,
		contactFormModal: action.modal
	})),

	on(featureActions.SetShowCompleted, (state, action) => ({
		...state,
		showCompleted: action.showCompleted,
	})),
);

export function getAccountingDocumentById(state: IDebtorPageState, id: number): IAccountingListItem {
	return getAccountingDocumentByIdInList(state.accountingDocuments, id);
}

